import "./App.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, Suspense } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import SignUp from "./views/signup";
import Login from "./views/login";
import { ForgotPassword } from "./views/forgotpassword";
import { CheckEmail } from "./views/checkemail";
import { SignupConfirmed } from "./views/signupconfirmed";
import PasswordResetForm from "./views/passwordresetform";
import { DashHeader } from "./views/dashboard/dashhome";
import { Errorpage } from "./404";
import { Notification } from "./views/dashboard/notification";
import { MessageHome } from "./views/message/messagehome";
import { Dashboard } from "./views/dashboard/dashboard";
import { ChatSection } from "./views/message/chatSection";
import { LikedPages } from "./views/pages/likedpages";
import ScrollToTop from "./scrollToTop";

import Media from "react-media";
import { Profile } from "./views/Profile/profile";
import { Settings } from "./views/settings/settings";
import { Group } from "./views/groups/group";
import { PagesLists } from "./views/pages/pageslists";
import { Pages } from "./views/pages/pages";
import { Explore } from "./views/explore/explore";
import { Technology } from "./views/explore/technology";
import { Events } from "./views/explore/events";
import { CreateStory } from "./views/dashboard/storyCreate/createStory";
import { StoriesCreate } from "./views/dashboard/storyCreate/stories";
import { TextStory } from "./views/dashboard/storyCreate/textStory";

import axios from "axios";

axios.defaults.baseURL = "http://localhost:8000/";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    redirectToDashboard();
  }, []);

  const redirectToDashboard = () => {
    if (localStorage.getItem("auth_token")) {
      navigate("dashboard");
    }
  };

  return (
    <>
      <ScrollToTop />

      <Media query="(max-width:1280px)">
        {(matches) =>
          matches ? (
            <Routes>
              <Route path="login">
                {localStorage.getItem("auth_token") ? (
                  redirectToDashboard
                ) : (
                  <Login />
                )}
              </Route>
              <Route path="/">
                {localStorage.getItem("auth_token") ? (
                  redirectToDashboard
                ) : (
                  <SignUp />
                )}
              </Route>
              <Route path="forgot-password">
                {localStorage.getItem("auth_token") ? (
                  redirectToDashboard
                ) : (
                  <ForgotPassword />
                )}
              </Route>
              <Route path="check-mail">
                {localStorage.getItem("auth_token") ? (
                  redirectToDashboard
                ) : (
                  <CheckEmail />
                )}
              </Route>{" "}
              <Route path="signup-confirmed">
                {localStorage.getItem("auth_token") ? (
                  redirectToDashboard
                ) : (
                  <SignupConfirmed />
                )}
              </Route>
              <Route path="password-reset-form">
                {localStorage.getItem("auth_token") ? (
                  redirectToDashboard
                ) : (
                  <PasswordResetForm />
                )}
              </Route>
              <Route path="/dashboard" element={<DashHeader />}>
                <Route index element={<Dashboard />}></Route>
                <Route path="notification" element={<Notification />}></Route>
                <Route path="message" element={<MessageHome />}></Route>
                <Route path="pmessage" element={<ChatSection />}></Route>
                <Route path="profile" element={<Profile />}></Route>
                <Route path="settings" element={<Settings />}></Route>
                <Route path="settings" element={<Settings />}></Route>
                <Route path="group" element={<Group />}></Route>
                <Route path="pageslists" element={<PagesLists />}></Route>
                <Route path="pages/:id" element={<Pages />}></Route>
                <Route path="explore" element={<Explore />}></Route>
                <Route path="Technology" element={<Technology />}></Route>
                <Route path="events" element={<Events />}></Route>
                <Route path="liked-pages" element={<LikedPages />}></Route>

                <Route path="create-story" element={<StoriesCreate />}>
                  <Route index element={<CreateStory />} />
                </Route>
              </Route>
              <Route path="*" element={<Errorpage />}></Route>
            </Routes>
          ) : (
            <Routes>
              {/* <Route path="/" element={<SignUp />}></Route> */}
              <Route path="login" element={<Login />}></Route>

              <Route
                path="forgot-password"
                element={<ForgotPassword />}
              ></Route>
              <Route path="check-mail" element={<CheckEmail />}></Route>

              <Route
                path="signup-confirmed"
                element={<SignupConfirmed />}
              ></Route>
              <Route
                path="password-reset-form"
                element={<PasswordResetForm />}
              ></Route>

              <Route path="/" element={<DashHeader />}>
                <Route index element={<Dashboard />}></Route>
                <Route path="notification" element={<Notification />}></Route>
                <Route path="message" element={<MessageHome />}>
                  <Route index element={<ChatSection />}></Route>
                </Route>

                <Route path="profile" element={<Profile />}></Route>
                <Route path="settings" element={<Settings />}></Route>
                <Route path="group" element={<Group />}></Route>
                <Route path="pageslists" element={<PagesLists />}></Route>
                <Route path="pages/:id" element={<Pages />}></Route>
                <Route path="explore" element={<Explore />}></Route>
                <Route path="Technology" element={<Technology />}></Route>
                <Route path="events" element={<Events />}></Route>

                <Route path="create-story" element={<StoriesCreate />}>
                  <Route index element={<CreateStory />} />
                  <Route path="text-story" element={<TextStory />} />
                </Route>
              </Route>

              <Route
                path="dashboard/pmessage"
                element={<Navigate to="/dashboard/message" />}
              ></Route>
              <Route path="*" element={<Errorpage />}></Route>
            </Routes>
          )
        }
      </Media>
    </>
  );
}

export default App;
