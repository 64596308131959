import { CreatePageForm } from './createpageform'
import React, {useState} from 'react'
import { Link, NavLink, useNavigate} from 'react-router-dom'

export const PageTopButton = () => {
   
    const [editProfile, setEditProfile] = useState(false); 
    const showEditProfile = () => {
        setEditProfile(!editProfile)
    }

    const navigate = useNavigate();

    const likedPages = () => {
      navigate('/dashboard/liked-pages');
    };
    const pageInvites = () => {
        navigate('/dashboard/page-invites');
      };
      const discover = () => {
        navigate('/dashboard/discover');
      };
  
  

    return (
<>
        {editProfile && (
            <CreatePageForm showEditProfile={showEditProfile} />
        )
        }     

        <div className="p-4">
        <div className='inline-block h-12 rounded-lg mx-1'>
                <button onClick={showEditProfile} className='bg-orange-600 text-white px-4 py-2 rounded-lg hover:bg-black'>Create Page <i className="fa-solid fa-plus ml-2"></i></button>
        </div>
        <div className='inline-block h-12 rounded-lg mx-1'>
                <button onClick={likedPages} className='bg-orange-600 text-white px-4 py-2 rounded-lg hover:bg-black'>Liked Pages <i class="fa-sharp fa-solid fa-thumbs-up ml-2"></i></button>
        </div>
        <div className='inline-block h-12 rounded-lg mx-1'>
                <button onClick={pageInvites} className='bg-orange-600 text-white px-4 py-2 rounded-lg hover:bg-black'>Invitations <i class="fa-solid fa-restroom ml-2"></i></button>
        </div>
        <div className='inline-block h-12 rounded-lg mx-1'>
                <button onClick={discover} className='bg-orange-600 text-white px-4 py-2 rounded-lg hover:bg-black'>Discover <i class="fas fa-search-plus ml-2"></i></button>
        </div>
</div>
</>
    )
}