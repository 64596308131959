



export const Shorts = () => {
    return(
        <>
        
        <p>Welcome</p>
        
        
        </>
    )
}